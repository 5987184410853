const GuaranteeDialog4i = function () {
	let localizedJS,
	
	init = () => {
		localizedJS = new localizedDetails();
		const guarantee360 = new Dialog4i();
		const triggers = [].slice.call(document.querySelectorAll(".the360link"));
		const dialogTitle = '<span class="visually-hidden">4imprint\'s 360 Degree Guarantee</span>';
		const dialogContent = getContent();
		guarantee360.Init({
			triggerElements: triggers, 
			dialogClass: "guaranteeDialog largeDialog", 
			title: dialogTitle, 
			content: dialogContent
		});
	},
	getContent = () => {
		let localeName = localizedJS.LocaleName,
		regOrTrade =  localizedJS.RegSM,
		certainDelivery = localizedJS.CertainDeliveryParagraph,
		certainHappiness = localizedJS.CertainHappinessParagraph,
		
		htmlContent =
			'<div class="posRelative">' +
				'<div id="modalDescription" class="visually-hidden">Beginning of dialog window. This dialog talks about 4imprint\'s 360 degree guarantee including our free samples, lowest prices, guaranteed satisfaction and on-time shipping. Escape will close this dialog window.</div>' +
				'<div class="displayFlex">' +
					'<div class="modalLeftSide">' +
						'<div class="marginTop20 marginLeft20">' +
							'<div class="headerLogo noLine inlineMiddle posRelative">' +
								'<svg class="textBlue" aria-label="4imprint Logo" height="52" width="138"><title>4imprint Logo</title><use xlink:href="#svgLogo-4imprint"></use></svg>' +
								'<span class="localeName">'+ localeName + '</span>' +
							'</div>' +
						'</div>' +
						'<picture>' +
							'<source srcset="//cdn.4imprint.com/qtz/details/detailsmvc/images/klt_tall2019.webp" type="image/webp" >' +
							'<source srcset="//cdn.4imprint.com/qtz/details/detailsmvc/images/klt_tall2019.png" type="image/png" >' +
							'<img class="kltImage" loading="lazy" src="//cdn.4imprint.com/qtz/details/detailsmvc/images/klt_tall2019.png" alt="Kevin Lyons-Tarr Signature" height="425" width="219" />' +
						'</picture>	' +
					'</div>' +
					'<div class="modalRightSide">' +
						'<p class="textDarkestGray text20 textCenter textSemiBold">Be certain with our</p>' +
						'<h3 id="modalTitle" class="text35 textBlue textCenter">360&deg; Guarantee<sub>' + regOrTrade + '</sub></h3>' +
						'<p class="textDarkestGray text16 marginBtm20 textCenter">You\'re covered from every angle.</p>' +
						'<h4 class="textBlue text20 textSemiBold"><span class="textBold">Certain</span> delivery</h4>' +
						'<p>' + certainDelivery + '</p>' +
						'<h4 class="textBlue text20 marginTop15 textSemiBold"><span class="textBold">Certain</span> value</h4>' +
						'<p>If you find within 30 days of purchase that your order would\'ve cost less elsewhere, let us know and we\'ll refund double the difference.</p>' +
						'<h4 class="textBlue text20 marginTop15 textSemiBold"><span class="textBold">Certain</span> happiness</h4>' +
						'<p>' + certainHappiness + '</p>' +
						'<p class="marginTop10 marginBtm10">' + '<a href="/info/whybuyfromus" class="paddingTop5 paddingBtm5">Learn More <span class="visually-hidden"> about our 360 guarantee</span></a>' + '</p>' +
						'<img class="marginTop10" src="//cdn.4imprint.com/qtz/details/detailsmvc/images/kevinsig.gif" alt="Kevin Lyons-Tarr" height="52" width="72" />' +
						'<p class="text16 kltEmpInfo"><span class="textBlue textBold">Kevin Lyons-Tarr</span><br />CEO, with 4imprint 32 years</p>' +
					'</div>' +
				'</div>' +
			'</div>';

		return htmlContent;
	};
	
	return{
		Init: init
	};
};

(function(){
	const guaranteeDialog = new GuaranteeDialog4i();
	guaranteeDialog.Init();
})();